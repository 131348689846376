import React from "react";
import Chart from "../components/Chart";
import Enclosure from "../components/Enclosure";
import OverallStatus from "../components/OverallStatus";
import { useQuery } from "react-query";
import axios from "axios";

function Index() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchData = async () => {
    const response = await axios.get(apiUrl);
    return response.data;
  };
  const { isLoading, error, data } = useQuery("status_data", fetchData, {
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  if (isLoading) return "loading...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <div className="App">
      <div className="container">
        <header>
          <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
            <a
              href="/"
              className="d-flex align-items-center text-dark text-decoration-none"
            >
              <span className="fs-4">Status Page</span>
            </a>

            <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
              {/* <a className="me-3 py-2 text-dark text-decoration-none" href="#">
                Home
              </a>
              <a className="me-3 py-2 text-dark text-decoration-none" href="#">
                Dashboard
              </a> */}
            </nav>
          </div>

          <OverallStatus status={data ? data.status : "PD"} />
          {data ? (
            Object.keys(data.services).map((key, index) => (
              <Enclosure data={data.services[key]}>
                <Chart
                  data={data.services[key]["days"]}
                  status={data.services[key].status}
                />
              </Enclosure>
            ))
          ) : (
            <></>
          )}
        </header>

        <main></main>
      </div>
    </div>
  );
}

export default Index;
