import React, { useState } from "react";

export default function Enclosure({ data, children }) {
  let badgeClass = "text-bg-success";
  let borderClass = "border-successx";
  if (data.status === "Service Down") {
    badgeClass = "text-bg-danger";
    borderClass = "border-dangerx";
  } else if (data.status === "Under Maintenance") {
    badgeClass = "text-bg-warning";
    borderClass = "border-warningx";
  }
  return (
    <div
      className={`p-3 pb-md-4 mx-auto my-1 row border ${borderClass} rounded`}
    >
      <div className="col-2 p-2">
        <div className="service">{data.name}</div>
      </div>
      <div className="col-10 p-2">{children}</div>

      <div className="col-2 p-0">
        <span className={`badge ${badgeClass}`}>{data.status}</span>
      </div>

      <div className="col-10 pt--3">
        <small className="text-muted">90 days ago </small>
        <small className="text-muted float-end">Today </small>
      </div>
    </div>
  );
}
