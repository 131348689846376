import React from "react";

export default function OverallStatus({ status }) {
  return (
    <div
      className={`alert ${
        status === "UP"
          ? "alert-success"
          : status === "PD"
          ? "alert-warning"
          : "alert-danger"
      } mb-5`}
    >
      {status === "UP"
        ? "All Systems Operational"
        : status === "PD"
        ? "Services Partially Down"
        : "Critical System Failure"}
    </div>
  );
}
