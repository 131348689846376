import React from "react";

export default function ({ data, status }) {
  return (
    <div className="chart" test={status}>
      {data.map((item, index, row) => (
        <div
          className={`bar ${
            item.total_downtime_recorded > 300 ||
            (index + 1 === row.length && status !== "Operational")
              ? item.total_downtime_recorded > 3600
                ? "red"
                : "yellow"
              : "green"
          }`}
        ></div>
      ))}
    </div>
  );
}
